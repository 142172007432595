import FsVideoEventHandler, { fsEvents } from './FsVideoEventHandler';
import TrackingUtils from './TrackingUtils';
import { convertTimeToSeconds, getParam } from './utils';

class JWPlayer {
  constructor (FNGPlayer, data) {
    // super();
    // general set up
    this.fngPlayer = FNGPlayer.default;
    this.data = data;
    this.elemId = data.elemId;
    this.playerCont = document.getElementById(this.elemId);
    this.playerParentEl = this.playerCont && this.playerCont.parentElement;
    this.multiple = data.multiple || false;
    this.player = undefined;
    this.enableLogs = location.href.includes('logs=true');
    this.auth = data.auth;
    this.trackingVideoData = {};
    this.trackingVideoDataMainContent = {};
    this.triggeredEvents = {};
    this.imageFitSize = data.imageFitSize || null;
    this.isContinuous = false;
    this.loadedPlayers = new Set();
    this.callOnIntervalFlag = {};
    // this.isFloodlightTrackingAllowed = false;
    this.isWatchPage = document.querySelector('.watch-page');
    // get segment script, this is required to be able to send tracking data to segment
    this.segmentAnalytics = window.SegmentAnalytics;
    this.deviceId = data.deviceId || this.segmentAnalytics ? this.segmentAnalytics.getUUID() : '';
    this.autoplay = data.autoplay === false ? data.autoplay : true;
    this.hasAutoplayed = false;
    const focusOnLoad = data.focusOnLoad === false ? data.focusOnLoad : true;
    /** init for live/vod */
    this.mode = data.airingType === 'live' || data.airingType === 'taped' || data.airingType === 'repeat'  ? 'live' : 'vod';
    this.videoId = data.id || data.stationID;
    this.isBonusFeed = this.isBonusFeedVideo();
    this.isMainFeed = this.isMainEventVideo();
    this.isLiveResume = false;
    if (data.isLiveNow && data.stationID) {
      this.videoId = data.stationID + '?epgListingId=' + data.id;
    }
    /** init for video clips */
    this.isClip = data.videoType === 'clip';
    if (this.isClip) {
      /** set up for embed */
      this.videoId = this.data.vid || this.data.id;
      // pausing other players if multiple players were to be used
      this.pauseOtherPlayers = this.pauseOtherPlayers.bind(this);
      this.data.durationInSeconds = convertTimeToSeconds(this.data.durationInSeconds);
      // init an empty set that will keep track all the videos that have been played
      this.playedVideos = new Set();
      // check for analytics
      if (this.segmentAnalytics) {
        this.pageMeta = this.segmentAnalytics.metaEl;
      }
      /**  set up for parsely tracking */
      // set utm object for tracking
      // if player if embed get proper utm params
      if (this.data.embed) {
        this.utmObj = TrackingUtils.getEmbedUtmParams(false, this.data.sportTag);
      } else {
        // check if player has its own utm params
        if (typeof this.data.utm !== 'undefined') {
          this.utmObj = TrackingUtils.getUtmObj(this.data.utm);
        } else {
          const pageName = this.data.pageName;
          if (pageName) {
            if (this.pageMeta) {
              this.data.pageName = pageName + ':' + this.pageMeta.page_type + (this.data.postLocation ? ':' + this.data.postLocation : '');
            }
            this.utmObj = TrackingUtils.getUtmObj(TrackingUtils.getUtmParams(this.data.pageName));
          }
        }
      }
      if (this.utmObj) {
        // setting default utm medium
        this.utmObj.utm_medium = 'jwplayer_' + this.utmObj.utm_medium;
      }

      this.enablePlaylist = typeof this.data.enablePlaylist === 'undefined' ? true : this.data.enablePlaylist;
      // adding playlist meta
      this.playlist = {
        current: this.data.url,
        previous: '',
        queue: [],
        currentIndex: 0,
        queueCompleted: false
      };
    

      this.setupFreewheel();
      this.setupMux();

      this.playlistLimit = 25;
      this.pubDate = data.pubDate;
      this.playVideo = this.playVideo.bind(this);
      if (data.mute) {
        this.data.isMuted = data.mute === false ? data.mute : true;
      }
    }

    this.image = this.isClip ? this.data.image : this.data.imageUrl;

    // Replace fit URL parameter if passed another.
    if (this.image && this.imageFitSize) {
      if (this.image.includes('fit=')) {
        this.image = this.image.replace(/(fit=).*?(&|$)/, '$1' + this.imageFitSize + '$2');
      } else {
        this.image += (this.image.includes('?') ? '&fit=' : '?fit=') + this.imageFitSize;
      }
    }

    this.image = this.image || 'https://b.fssta.com/uploads/2016/12/default_image.png';

    /** End of clip setup */

    const isMuted = this.getPlayerMuteState();

    let watch = {};
    if (data.watch) {
      watch = data.watch;
    } else {
      watch = {
        streamId: this.videoId,
        streamType: 'vod'
      }
    }

    // configuring the player..
    this.playerOptions = {
      auth: {
        ...this.auth
      },
      data: {
        player: {}
      },
      autoplay: this.autoplay,
      container: data.container,
      id: this.elemId,
      image: this.image,
      videoID: this.videoId,
      // ...mediaInfo,
      watch,
      params: this.getParamsObject(),
      width: '100%',
      height: '100%',
      mode: this.mode,
      restart: !!data.isRestart,
      focusOnLoad,
      mute: isMuted,
      // general events
      onPlayerLoadComplete: (e) => {
        performance.mark('fs-jwplayer-onplayerloadedcomplete');
        // only dispatch the event once if loaded player is not found
        if (!this.loadedPlayers.has(this.elemId)) {
          FsVideoEventHandler.dispatchEvent(fsEvents.playerReady, { e, player: this.player, playerData: this.data, fngPlayer: this });
          this.loadedPlayers.add(this.elemId);
        }

        // adding a listener to the sound icon to know when clicked
        const volumeControl = document.querySelector(`#${this.elemId} .volumeContainer`);
        if (volumeControl) {
          volumeControl.addEventListener('click', (e) => {
            // this.handleMuteInteractions(e);
            this.handleMuteState(this.elemId);
          });
        }

        this.log('[reference] onPlayerLoadedComplete: ', e);
      },
      onReady: (e) => {
        performance.mark('fs-jwplayer-onready');
        // sets all video segment analytics props.
        this.trackingVideoData = this.setVideoProps(this.data, e);
        this.trackingVideoDataMainContent = this.setMainContentProps(this, e);
        // new elememt is added after player completes
        this.log('[reference] onReady: ', e);
      },
      onFullScreen: (e) => {
        performance.mark('fs-jwplayer-onfullscreen');
        this.isFullScreen = e.fullscreen;
        if (this.isClip) {
          this.isFullScreen ? this.playerParentEl.classList.add('full-screen')
            : this.playerParentEl.classList.remove('full-screen');
        }
        this.log('[reference] onFullScreen: ', e);
      },
      onPlayerError: async (e) => {
        performance.mark('fs-jwplayer-onplayererror');
        FsVideoEventHandler.dispatchEvent(fsEvents.playerError, { e, player: this });
        this.log('[reference] onPlayerError: ', e);

        if (e.errorCode === 401) {
          const wisReg = window.wisRegistration;
          if (wisReg) {
            // let's remove existing anon jwt just in case it's expired
            wisReg.removeStoredLoginAnon();
            // get a new anon jwt
            await wisReg.registerAnonUserThrottler();

            // log out user if they are logged in since JWT from here would cause the 401 before anon token
            if (wisReg.isUserLoggedIn()) {
              // this will refresh page
              wisReg.logoutUser(true, true);
            }
          }
        }
      },
      onPlay: (e) => {
        performance.mark('fs-jwplayer-onplay');
        this.sendPlay();
        this.log('[reference] onPlay: ', e);
      },
      onPause: (e) => {
        performance.mark('fs-jwplayer-onpause');
        this.trackVideoEvent(fsEvents.playbackPaused, this, e);
        this.sendPause();
        this.log('[reference] onPause: ', e);
        this.isLiveResume = this.data.isLiveNow;
      },
      onResume: (e) => {
        this.trackVideoEvent(fsEvents.playbackResumed, this, e);
        this.log('[reference] onResume: ', e);
      },
      // media events
      onMediaStart: (e) => {
        performance.mark('fs-jwplayer-onmediastart');
        if (this.isClip) {
          this.pauseOtherPlayers();
          // this is part of parsely tracking
          this.utmObj && TrackingUtils.sendPageViewTracking(this);
        } else {
          this.reqDarTag();
          this.handleMuteState();
        }
        this.log('[reference] onMediaStart: ', e);
      },
      onMediaPlaying: (e) => {
        performance.mark('fs-jwplayer-onmediaplaying');
      },
      onMediaComplete: (e) => {
        performance.mark('fs-jwplayer-onmediacomplete');
        // disables default playlist behavior
        (this.isClip && this.data.isWatchPage) && this.playNextVideo();
        if(this.autoplay) {
          this.hasAutoplayed = true;
        }
        FsVideoEventHandler.dispatchEvent(fsEvents.videoEnd, { e, player: this });
        this.log('[reference] onMediaComplete: ', e);
      },
      onMediaError: (e) => {
        performance.mark('fs-jwplayer-onmediaerror');
        this.trackVideoEvent(fsEvents.videoError, this, e);
        FsVideoEventHandler.dispatchEvent(fsEvents.videoError, { e, player: this });
        this.log('[reference] onMediaError: ', e);
      },
      // slate events 
      onSlateStart: (e) => {
        performance.mark('fs-jwplayer-onslatestart');
        FsVideoEventHandler.dispatchEvent(fsEvents.slateStart, { e, player: this });
        this.trackVideoEvent(fsEvents.slateStart, this, e);
        this.log('[reference] onSlateStart: ', e);
      },
      onSlatePlaying: (e) => {
        if (e.trackingData.slateSecondsViewed && e.trackingData.slateSecondsViewed > 0) { this.setSecondsViewed(e.trackingData.slateSecondsViewed); }

        this.callWithInterval('onSlatePlaying', e.trackingData.slateSecondsViewed, 30, () => {
          performance.mark('fs-jwplayer-onslateplaying');
          this.trackVideoEvent(fsEvents.slatePlaying, this, e);
          this.log('[reference] onSlatePlaying: ', e);
        });
      },
      onSlateComplete: (e) => {
        performance.mark('fs-jwplayer-onslatecomplete');
        this.trackVideoEvent(fsEvents.slateComplete, this, e);
        this.log('[reference] onSlateComplete: ', e);
      },
      // ad events
      onAdStart: (e) => {
        performance.mark('fs-jwplayer-onadstart');
        this.isClip && this.pauseOtherPlayers();
        this.trackVideoEvent(fsEvents.adStart, this, e);
        this.log('[reference] onAdStart: ', e);
      },
      onAdPlaying: (e) => {
        this.callWithInterval('onAdPlaying', e.currentTime, 5, () => {
          performance.mark('fs-jwplayer-onadplaying');
          this.trackVideoEvent(fsEvents.adProgress, this, e);
          this.log('[reference] onAdPlaying: ', e);
        });
      },
      onAdComplete: (e) => {
        performance.mark('fs-jwplayer-onadcomplete');
        this.trackVideoEvent(fsEvents.adComplete, this, e);
        this.log('[reference] onAdComplete: ', e);
      },
      onAdBufferStart: (e) => {
        this.trackVideoEvent(fsEvents.adBufferStart, this, e);
        this.log('[reference] onAdBufferStart: ', e);
      },
      onAdBufferEnd: (e) => {
        this.trackVideoEvent(fsEvents.adBufferEnd, this, e);
        this.log('[reference] onAdBufferEnd: ', e);
      },
      onAdPause: (e) => {
        this.trackVideoEvent(fsEvents.adPause, this, e);
        this.log('[reference] onAdBufferEnd: ', e);
      },
      onAdResume: (e) => {
        this.trackVideoEvent(fsEvents.adResume, this, e);
      },
      onAdPodStart: (e) => {
        this.trackVideoEvent(fsEvents.adPodStart, this, e);
        this.log('[reference] onAdPodStart: ', e);
      },
      onAdPodComplete: (e) => {
        this.trackVideoEvent(fsEvents.adPodComplete, this, e);
        this.log('[reference] onAdPodComplete: ', e);
      },
      // buffer events
      onBufferStart: (e) => {
        this.trackVideoEvent(fsEvents.bufferStart, this, e);
        this.log('[reference] onBufferStart: ', e);
      },
      onBuffering: (e) => {
        this.log('[reference] onBuffering: ', e);
      },
      onBufferEnd: (e) => {
        this.trackVideoEvent(fsEvents.bufferEnd, this, e);
        this.log('[reference] onBufferEnd: ', e);

        if (this.isLiveResume) {
          this.trackVideoEvent(fsEvents.playbackResumed, this, e);
          this.log('[reference] onResume: ', e);
          this.isLiveResume = false;
        }
      },
      // seek events
      onSeekStart: (e) => {
        this.trackVideoEvent(fsEvents.videoSeekStart, this, e);
        this.log('[reference] onSeekStart: ', e);
      },
      onSeekComplete: (e) => {
        this.trackVideoEvent(fsEvents.videoSeekEnd, this, e);
        this.log('[reference] onSeekComplete: ', e);
      },
      // playback events
      onPlaybackStart: (e) => {
        this.processTriggeredEvent(fsEvents.playbackStart);
        this.trackingVideoDataMainContent = this.setMainContentProps(this, e);
        this.trackVideoEvent(fsEvents.playbackStart, this, e);
        // this.isFloodlightTrackingAllowed = true;
        this.isLiveResume = false;

        this.log('[reference] onPlaybackStart: ', e);
      },
      onPlaybackComplete: (e) => {
        this.processTriggeredEvent(fsEvents.playbackComplete);
        this.trackVideoEvent(fsEvents.playbackComplete, this, e);
        this.log('[reference] onPlaybackComplete: ', e);
      },
      onPlaybackInterrupted: (e) => {
        this.trackVideoEvent(fsEvents.playbackInterrupted, this, e);
        this.log('[reference] onPlaybackInterrupted: ', e);
      },
      onContentStart: (e) => {
        if (e.secondsViewed && e.secondsViewed > 0) { this.setSecondsViewed(e.secondsViewed); }
        this.trackingVideoDataMainContent = this.setMainContentProps(this, e);

        FsVideoEventHandler.dispatchEvent(fsEvents.contentStart, { e, player: this });
        this.trackVideoEvent(fsEvents.contentStart, this, e);

        // Fennec
        const fen = window.fennec
        if (fen) {
          fen.deltaContentStart(e)
        }

        // if (this.isFloodlightTrackingAllowed) {
        //   // facebook
        //   FbTracking({ mode: this.mode, isClip: this.isClip });
        //   // floodlight
        //   GTMTracking(this.getGTMTProps(e));

        //   this.isFloodlightTrackingAllowed = false;
        // }

        this.log('[reference] onContentStart: ', e);
      },
      onContentComplete: (e) => {
        if (e.secondsViewed && e.secondsViewed > 0) { this.setSecondsViewed(e.secondsViewed); }
        this.trackingVideoDataMainContent = this.setMainContentProps(this, e);
        this.trackVideoEvent(fsEvents.contentComplete, this, e);
        this.log('[reference] onContentComplete: ', e);
      },
      onContentPlaying: (e) => {
        this.trackingVideoDataMainContent = this.setMainContentProps(this, e);

        if (e.secondsViewed && e.secondsViewed > 0) { this.setSecondsViewed(e.secondsViewed); }

        this.callWithInterval('onContentPlaying', e.secondsViewed, 30, () => {
          this.trackVideoEvent(fsEvents.contentPlaying, this, e);
          // Fennec
          const fen = window.fennec
          if (fen) {
            fen.deltaContentPlaying(e)
          }
          this.log('[reference] onContentPlaying: ', e);
        });

        this.prev = this.prev || 0;
        const isOneSecflag = Math.floor(e.secondsViewed);

        if (isOneSecflag == 0 && this.prev > 0) {
          this.prev = 0;
        } else if (isOneSecflag > this.prev) {
          this.prev = isOneSecflag;
          // _segHBPlayheads - heartbeat
          if (e.trackingData && e.trackingData.video) {
            if (!window._segHBPlayheads) {
              window._segHBPlayheads = {};
            }
            const sid = this.trackingVideoData.session_id;
            window._segHBPlayheads[sid] = e.trackingData.video.position;
          }
        }
      },
      // chrome cast events
      onChromecastStart: (e) => {
        // GTMTracking(this.getGTMTProps(e));
        this.log('[reference] onChromecastStart: ', e);
      },
      onChromecastComplete: (e) => {
        // GTMTracking(this.dougetGTMTProps(e));
        this.log('[reference] onChromecastComplete: ', e);
      },
      // restart
      onRestartButtonClicked: (e) => {
        FsVideoEventHandler.dispatchEvent(fsEvents.restartStart, { e, player: this });
        this.log('[reference] onRestartButtonClicked: ', e);
      },
      onMute: (e) => {
        this.log('[reference] onMute: ', e);
      },
      onExitEventStream: (e) => {
        FsVideoEventHandler.dispatchEvent(fsEvents.exitEventStream, { e, player: this });
        this.log('[reference] onExitEventStream: ', e);
      }
    };

    this.loadPlayer();

  }

  callWithInterval (eventName, secondsViewed, interval, callback) {
    // keep track of events each event flag (how many times events has fired so far)
    let callOnInterval = this.callOnIntervalFlag[eventName]
    // default to 0
    callOnInterval = callOnInterval || 0;
    //total seconds view from player event props / interval (e.i 30 or 10 seconds)
    const flag = Math.floor(secondsViewed / interval);
    // if flag is zero set interval to 0 / else flag is bigger than interval assign flag to interval and call the callback where event fires
    if (flag === 0 && callOnInterval > 0) {
      callOnInterval = 0;
    } else if (flag > callOnInterval) {
      callOnInterval = flag;
      callback()
    }

    this.callOnIntervalFlag[eventName] = callOnInterval
  }

  /**
     * gets floodlight google tracking props
     */
  getGTMTProps (e) {
    let trackingData = { ...this.trackingVideoData };

    if (e.trackingData) {
      trackingData = {
        ...trackingData,
        ...e.trackingData.content,
        ...e.trackingData.playback,
        ...e.trackingData.video,
        sid: this.player.options.releaseInfo.sid
      };
    }

    return {
      isClip: this.isClip,
      videoContentLength: this.isClip ? 'short-form' : 'long-form',
      trackingData
    };
  }

  /**
     * gets params object which contains web data needed for analytics (page data, test data, etc..)
     */
  getParamsObject () {
    const params = {};
    const doNotSellCookie = this.getDoNotSellCookie();
    const adDebug = this.getCookie('DEBUG_PANEL_AD_DEBUG');
    const adSiteSection = this.getCookie('DEBUG_PANEL_AD_SITE_SECTION');

    if (doNotSellCookie || adDebug || adSiteSection) {
      params.ads = {
        privacyParameter: doNotSellCookie,
        debugParameter: adDebug,
        siteSection: adSiteSection
      };
    }

    // setting vcid 2
    if (params.ads) {
      params.ads.publisherId = this.deviceId;
      const xid = this.getCookie('xid');
      if (xid) {
        params.ads.xid = xid;
      }
    }

    return params;
  }

  /**
     * gets privacy cookie value
     */
  getDoNotSellCookie () {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + 'us_privacy' + '=([^;]*)'
    ));

    return matches ? matches[1] : null;
  }

  /**
     * loads a video onto the player
     * @param {obj} metadata - video metadata
     * @param {boolean} playback - whether to start a playback or not
     */
  playVideo (metadata, playback = true, isLive = false) {
    const siteSection = this.data.siteSection;
    // Update data
    this.data = metadata.videoConfig || metadata;
    // revert site section
    this.data.siteSection = siteSection;
    // set title - we need to override the title within video config object cuz that one adds special chars.
    this.data.title = metadata.title;
    // update video id
    this.player.options.videoID = this.data.id;
    // update Mux props
    this.setupMux();
    // reset media info
    this.resetMediaInfo(metadata, isLive);
    // load new release url into the player
    playback ? this.player.playRelease() : this.player.loadRelease();
  }

  /**
     * Plays next video
     */
  playNextVideo () {
    if (this.playlist.queue.length === 0) {
      this.player.setConfig({ autoplay: false });
      this.player.loadRelease();
      return;
    }
    // get current url
    let metadata = this.playlist.queue[this.playlist.currentIndex];
    // set previous url
    this.playlist.previous = this.data.url;
    // if the is an available video to play
    const available = this.playlist.currentIndex < this.playlist.queue.length;
    // assign metadata depending on availability
    metadata = available ? metadata : this.data;
    if (available) {
      // update current release url
      this.playlist.current = this.data.url;
      // update current index
      this.playlist.currentIndex += 1;
    } else { // once playlist ends, load but don't play the original video
      // workaround flag to stop video playback of the first video
      this.playlist.queueCompleted = true;
      // reset index to zero
      this.playlist.currentIndex = 0;
    }
    // play video
    this.playVideo(metadata, available);
  }

  /**
     * This method resets the player media info when a playback is triggered
     * TODO: add a param that supports overriding of media ifo
     */
  resetMediaInfo (metadata, isLive = false) {
    const videoType = isLive ? 'live' : 'vod'
    const id = isLive ? metadata.id : metadata.external_id;
    this.player.setConfig({ watch: {streamId: id, streamType: videoType }});
  }
  /**
     * loads the player
     */
  loadPlayer () {
    this.fngPlayer.configPlayer(() => {
      this.fngPlayer.load(this.playerOptions, function (playerInstance) {
        performance.mark('fs-jwplayer-loadplayer');
        this.player = playerInstance;
        // set platfrom version
        const utag = window.utag;
        if (utag) {
          utag.data.platform_version = 'fscom web|v' + this.player.version + ` (${window.utag_data.build_version})`;
        }
      }.bind(this));
    });
  }

  /**
     * ad Dar Tag for live content
     */
  reqDarTag () {
    if (this.data.videoType === 'clip') { return; }
    const contentId = this.data.sportTag ? this.data.sportTag : 'studio_show';
    const darTag = `https://secure-gl.imrworldwide.com/cgi-bin/m?ca=nlsn154357
            &cr=creative&ce=fox&pc=${contentId}&ci=nlsnci388&am=4&at=view&rt=banner&st=image&c9=devid,
            &c13=asid,P5CFA3B51-3361-481F-B75D-D119A71FF616&r=${Date.now()}`;

    fetch(darTag);
  }

  getCookie (name) {
    const foundCookie = document.cookie.split('; ').find(c => c.includes(`${name}=`)) || '';
    return foundCookie.split('=')[1] || null;
  }

  /**
     * returns url params
     */
  getUrlParams () {
    const urlQuery = location.search;
    const videoUrl = getParam(urlQuery, 'videourl');
    return {
      releaseURL: decodeURIComponent(videoUrl)
    };
  }

  /**
     * @desc pause other players in the page triggered by setPlay method
     */
  pauseOtherPlayers () {
    let players = [];
    try {
      // returns all players except the current player the user is interacting with
      players = this.getAllPlayers();
    } catch (e) {
      this.log('VIDEO: error - ', e);
    }

    if (players.length > 0) {
      players.forEach((fsPlayer) => {
        fsPlayer.isPinned && fsPlayer.unpin(fsPlayer.parentEl);
        fsPlayer.player.pause();
      });
    }
  }

  getMetadata () {
    return {
      title: this.data.title,
      image_url: this.data.thumbnail,
      section: this.data.categoryName,
      id: this.data.vid,
      url: this.data.url
    };
  }

  /**
     * sends play
     */
  sendPlay () {
    if (!this.isClip) { return; }
    this.pauseOtherPlayers();
    if (this.utmObj) {
      TrackingUtils.sendTracking('play', this.getMetadata(), this.utmObj);
    }
  }

  /**
     * send pause
     */
  sendPause () {
    if (!this.isClip) { return; }
    if (this.utmObj) {
      TrackingUtils.sendTracking('pause', this.getMetadata(), this.utmObj);
    }
  }

  log () {
    this.enableLogs && console.log.apply(console, arguments);
  }

  // analytics helper methods
  /**
     * track video event
     * @param {*} eventName
     * @param {*} data
     * @param {*} e
     */
  trackVideoEvent (eventName, data, e) {
    if (!this.segmentAnalytics) { return; }
    this.segmentAnalytics.trackVideoEvent(eventName, data, e);
  }

  /**
     * set seconds viewed
     * @param {*} secondsViewed
     */
  setSecondsViewed (secondsViewed) {
    if (!this.segmentAnalytics) { return; }
    this.segmentAnalytics.setSecondsViewed(secondsViewed);
  }

  /**
     * set video props
     * @param {*} data
     * @param {*} e
     */
  setVideoProps (data, e) {
    if (!this.segmentAnalytics) { return {}; }
    return this.segmentAnalytics.setVideoProps(data, e);
  }

  setMainContentProps (data, e) {
    if (!this.segmentAnalytics) { return {}; }
    return this.segmentAnalytics.setMainContentProps(data, e);
  }

  /**
     * add event to triggeredEvents object
     * @param eventName
     */
  processTriggeredEvent (eventName) {
    if (this.triggeredEvents[eventName]) {
      this.triggeredEvents[eventName]++;
    } else {
      this.triggeredEvents[eventName] = 1;
    }
    
    this.setIsContinuous(eventName);
    // TODO: REMOVE THIS WHEN WATCH IS USING SAME PLAYER INSTANCE
    if (window.fngPlayers.length > 1 && this.isWatchPage) { this.isContinuous = true }
  }

  /**
     * set isContinuous
     * @param eventName
     */
  setIsContinuous (eventName) {
    // no need to do anything if video_is_continuous is already set to true
    if (this.isContinuous) { return; }
    if (![fsEvents.playbackStart, fsEvents.playbackComplete].includes(eventName)) { return; }

    // set video_is_continuous to true when starting new playback and fsEvents.playbackComplete has already been triggered
    if (eventName === fsEvents.playbackStart && this.triggeredEvents[fsEvents.playbackComplete]) {
      this.isContinuous = true;
    }
  }

  /**
     * setup freewheel props
     */
  setupFreewheel () {
    if (!this.isClip || !this.data.trackingData || !this.data.trackingData.properties) { return; }

    const playerLocation = `${this.pageMeta && this.pageMeta.category === 'home' ? 'home' : 'video'}page`;
    const freewheelSiteSection = `fsapp/${this.getFreewheelDevice()}/vod/${playerLocation}`;
    this.data.trackingData.properties.freewheelSiteSection = this.data.siteSection = freewheelSiteSection;
  }

  /**
     * get freewheel param id url param
     * e.g. extra=%26ad.prof%3D516429%3AUplynk_FOXSports_webdesktop
     * @returns {string}
     */
  getFreewheelProfileIdParam () {
    return `extra=%26ad.prof%3D516429%3AUplynk_FOXSports_${this.getFreewheelDevice()}`;
  }

  /**
     * get freewheel device prop based on physical device type
     *
     * @returns {string}
     */
  getFreewheelDevice () {
    let device = 'webdesktop';
    if (this.viewport && !this.viewport.desktop()) {
      device = this.viewport.mobile() ? 'webmobilehandheld' : 'webmobiletablet';
    }

    return device;
  }

  /**
     * add mux props to tracking data
     */
  setupMux () {
    if (!this.isClip || !this.data.trackingData || !this.data.trackingData.properties) { return; }

    this.data.trackingData.properties.mux_video_title = this.data.title || this.data.seriesName || '';
    this.data.trackingData.properties.mux_video_stream_type = 'vod';
    this.data.trackingData.properties.mux_player_name = 'foxsports_fsapp_web_en-US';
    this.data.trackingData.properties.mux_viewer_user_id = '';
    this.data.trackingData.properties.mux_video_series = '';
    this.data.trackingData.properties.mux_experiment_name = '';
    this.data.trackingData.properties.mux_video_cdn = 'edgecast';
    this.data.trackingData.properties.mux_sub_property_id = 'vdms';
    this.data.trackingData.properties.CDN = 'EDGECAST';

    if (!this.data.trackingData.properties.mux_viewer_user_id && this.segmentAnalytics) {
      const profile = this.segmentAnalytics.getProfileData();
      this.data.trackingData.properties.mux_viewer_user_id = profile.dcg_profile_id || '';
    }
  }

  getPlayerMuteState () {
    if (this.data && this.data.isMuted) { return this.data.isMuted; }

    const main = this.getMainLivePlayer();
    if (main) {
      const isMuted = this.data.eventShowType && this.data.eventShowType === 4 && !main.player.muted;
      return isMuted;
    }
    return false;
  }

  /**
     * check if player stream is a main feed
     */
  isMainEventVideo () {
    return this.data.eventShowType === 1;
  }

  /**
     * check if player stream is a bonus feed
     */
  isBonusFeedVideo () {
    return this.data.eventShowType && this.data.eventShowType === 4;
  }

  /**
     * get main video event
     */
  getMainLivePlayer () {
    const players = window.fngPlayers;
    return players.find(x => x.isMainFeed);
  }

  /**
     * get all bonus feeds but current one
     */
  getAllPlayers (playerId) {
    const players = window.fngPlayers;
    return players.filter(x => x.elemId !== (playerId || this.elemId));
  }

  /**
     * Pause all bonus feeds, supports muting them as well
     * @param {boolean} mute mutes bonus feed instead of pausing them
     */
  handleMuteState (interactedPlayerId) {
    if (!window.lastKnowPlayerId) {
      window.lastKnowPlayerId = this.elemId;
    }
    if (interactedPlayerId) {
      window.lastKnowPlayerId = interactedPlayerId;
    }
    const players = this.getAllPlayers(window.lastKnowPlayerId);
    players.forEach((x) => {
      if (!x.player.muted) {
        x.player.mute();
      }
    });
  }
}

export default JWPlayer;
